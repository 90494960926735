import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Typography from "../typography";
import CloseIcon from "../../icons/close";
import BackspaceIcon from "../../icons/backspace";

import styles from "./styles.module.css";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > .MuiBackdrop-root": {
      background: "#212224cc",
    },
  },
  paper: {
    height: 514,
    width: 464,
    borderRadius: 12,
    border: 0,
    background: "#FFFFFF",
    boxShadow: "0px 6px 20px rgba(63, 108, 168, 0.15)",
    outline: "none",
  },
}));

interface Props {
  open: boolean;
  title?: string | React.ReactNode;
  backBtnVisible?: boolean;
  footer?: JSX.Element;
  children: React.ReactNode;
  onToggleModal?: () => void;
  onBack?: () => void;
  hideClose?: boolean;
  hideHeader?: boolean;
  classNameBody?: string;
}
const CustomModal: React.FC<Props> = ({
  open,
  title,
  backBtnVisible,
  footer,
  children,
  onToggleModal,
  onBack,
  hideClose,
  hideHeader,
  classNameBody,
}) => {
  const classes = useStyles();

  const onClose = (event: object, reason: string) => {
    if (reason === "backdropClick") return;
    if (onToggleModal) {
      onToggleModal();
    }
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus
    >
      <Fade in={open}>
        <div
          className={classNames(styles.modalBody, classNameBody, styles.animateBottom)}
        >
          {!hideHeader && (
            <div className={`flex-align-center ${styles.modalHeader}`}>
              <Typography name="subTitle1" type="bold" className="flex-align-center">
                {backBtnVisible && (
                  <button
                    onClick={onBack}
                    className={`flex-align-center ${styles.backBtn}`}
                  >
                    <BackspaceIcon />
                  </button>
                )}
                {title}
              </Typography>
              {!hideClose && (
                <button onClick={onToggleModal}>
                  <CloseIcon />
                </button>
              )}
            </div>
          )}
          <div className={styles.modalContent}>{children}</div>
          {footer && (
            <div className={`flex-align-center ${styles.modalFooter}`}>{footer}</div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
