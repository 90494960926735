import React from "react";

const BackspaceIcon = ({ color = "#415168", className = "" }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.37114e-07 7L12.17 7L8.59 10.59L10 12L16 6L10 -5.24537e-07L8.59 1.41L12.17 5L6.11959e-07 5L4.37114e-07 7Z"
      fill={color}
    />
  </svg>
);

export default BackspaceIcon;
