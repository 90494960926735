import * as React from "react";

import vendorloadable from "@loadable/component";
import Loader from "components/common/loaderWrapper";

const loadable = (constructor: any, options = {}) => {
  return vendorloadable(constructor, {
    fallback: <Loader />,
    ...options,
  });
};

export default loadable;
