import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import ErrorBoundary from "components/errorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";

interface Props {
  children: React.ReactNode;
}

const sentryParams = !process.env.REACT_APP_SENTRY_DISABLE
  ? {
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      release: "marketplace@" + process.env.REACT_APP_ENV_NAME,
      tunnel: "https://tunnel.attrace.com/.netlify/functions/tunnel",
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV_NAME,
    }
  : {};
Sentry.init(sentryParams);

const queryClient = new QueryClient();

function AppProvider({ children }: Props) {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ErrorBoundary>
  );
}

export default AppProvider;
