import React, { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import styles from "./styles.module.css";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  size?: "small" | "medium" | "large";
  look?: "ghost" | "secondary" | "textOnly";
  mainAction?: boolean;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
}
const Button: React.FC<Props> = ({
  children,
  className = "",
  size,
  look,
  mainAction,
  onClick,
  type = "button",
  style,
  ...rest
}) => {
  const btnClass = classNames(styles.btn, {
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.large]: size === "large",
    [styles.ghost]: look === "ghost",
    [styles.secondary]: look === "secondary",
    [styles.textOnly]: look === "textOnly",
    [styles.mainAction]: mainAction,
    [className]: className,
  });
  return (
    <button className={btnClass} onClick={onClick} {...rest} type={type} style={style}>
      {children}
    </button>
  );
};

export default Button;
