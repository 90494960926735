import React from "react";

import classNames from "classnames";

import styles from "./styles.module.css";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  name: "caption" | "labels" | "bodyText" | "subTitle2" | "subTitle1" | "subTitle0";
  type?: "normal" | "bold" | "medium";
  className?: string;
}

const Typography: React.FC<Props> = ({ children, className = "", name, type, style }) => {
  const typographyClass = classNames(styles.typography, {
    [styles.caption]: name === "caption",
    [styles.labels]: name === "labels",
    [styles.bodyText]: name === "bodyText",
    [styles.subTitle2]: name === "subTitle2",
    [styles.subTitle1]: name === "subTitle1",
    [styles.subTitle0]: name === "subTitle0",
    [styles.normal]: type === "normal",
    [styles.bold]: type === "bold",
    [styles.medium]: type === "medium",
    [className]: className,
  });
  return (
    <p className={typographyClass} style={style}>
      {children}
    </p>
  );
};

export default Typography;
