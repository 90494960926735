import React from "react";

import Typography from "components/common/typography";
import Button from "components/common/button";
import FailedIcon from "components/icons/failed";
import Modal from "components/common/modal";

import styles from "./styles.module.css";

export interface ErrorModalProps {
  open: boolean;
  onToggleModal: () => void;
  error?: Error;
  canClose?: boolean;
  onClose?: VoidFunction;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  open,
  onToggleModal,
  error,
  canClose = false,
  onClose,
}) => {
  return (
    <Modal open={open} onToggleModal={onToggleModal} title="Error">
      <div>
        <div className={`flex-align-center ${styles.modalBodyMainText}`}>
          <div className="flex-align-center">
            <FailedIcon />
          </div>
          <Typography name="bodyText">Sorry, something went wrong.</Typography>
        </div>
        {error?.message && (
          <Typography name="labels" className={styles.errMsg}>
            Error: {error?.message}
          </Typography>
        )}
        {canClose && (
          <Button
            onClick={onClose}
            look="ghost"
            size="medium"
            className={styles.closeBtn}
          >
            Close
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ErrorModal;
