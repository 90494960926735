import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react/dist/errorboundary";
import React, { useState } from "react";

import ErrorModalContextProvider from "../../providers/errorModalProvider";

import ErrorModal from "../modals/errorModal";

export const ErrorFallback: FallbackRender = ({ error }) => {
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ErrorModal
      onToggleModal={onClose}
      open={open}
      error={error}
      onClose={onClose}
      canClose={true}
    />
  );
};

const ErrorBoundary: React.FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={undefined}>
      <ErrorModalContextProvider.Provider>{children}</ErrorModalContextProvider.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
