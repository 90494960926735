import React from "react";
import classNames from "classnames";

import styles from "./styles.module.css";

const Loader: React.FC<any> = ({ classNameWrapper, className }) => (
  <div className={classNames(styles.wrapper, classNameWrapper)}>
    <div className={classNames(styles.circle, className)}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 3.04762C16.01 3.04762 3.04762 16.01 3.04762 32C3.04762 47.99 16.01 60.9524 32 60.9524C47.99 60.9524 60.9524 47.99 60.9524 32C60.9524 16.01 47.99 3.04762 32 3.04762ZM0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
          fill="#E6E9F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.9524 32C60.9524 16.01 47.99 3.04762 32 3.04762V0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32H3.04762C3.04762 47.99 16.01 60.9524 32 60.9524C47.99 60.9524 60.9524 47.99 60.9524 32Z"
          fill="#0081F9"
        />
      </svg>
    </div>
    <div className={styles.logo}>
      <svg
        width="38"
        height="34"
        viewBox="0 0 38 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.0523 13.6876L27.0073 13.6853C25.6048 13.6629 24.445 13.869 23.1975 14.5388V14.5365C21.6085 15.3295 19.3226 17.9167 17.5942 20.8041C15.6747 23.6332 11.8964 30.9333 8.62607 31.4149L7.89558 31.4172C5.33327 31.4172 3.25644 29.3474 3.25644 26.7938C3.25644 24.341 8.08663 17.2268 9.44196 14.886L14.7127 5.78935L14.7576 5.69303C15.5308 4.05335 17.1806 3.00727 18.9967 3.00727C20.8128 3.00727 22.4648 4.05335 23.2358 5.69303L23.2807 5.78935L27.1399 12.4511C28.3581 12.5004 29.5292 12.8073 30.5833 13.3337L25.5351 4.61783C24.3461 2.09559 21.8018 0.476074 18.9967 0.476074C16.1894 0.476074 13.6473 2.09559 12.4583 4.61783L7.24376 13.6204C5.4479 16.7161 0.714355 23.4383 0.714355 26.7938C0.714355 28.6485 1.42461 30.3397 2.58664 31.612H2.56866C3.91725 33.1061 5.85697 33.9999 7.94054 33.9999C13.2472 33.9999 16.5872 26.9663 19.5451 22.9074L23.5324 29.7887C24.7215 32.3109 27.2635 33.9282 30.0731 33.9282C31.9724 33.9282 33.7525 33.1868 35.0719 31.9256V31.9615C36.4362 30.6578 37.2858 28.8255 37.2858 26.7938C37.2858 24.3589 34.8763 20.3807 33.548 18.181C32.7118 16.7407 32.3792 15.9746 30.8778 14.9397L30.8553 14.9241L30.8306 14.9084L30.7856 14.877H30.7834L30.7744 14.8703L30.7362 14.8434C29.7697 14.2185 28.2098 13.6517 27.0523 13.6876ZM34.4066 28.532C34.6246 27.9967 34.746 27.4098 34.746 26.7938C34.746 25.0892 32.4399 21.3305 31.3992 19.534C30.0416 17.2738 29.019 15.9388 25.9622 16.2591L25.9442 16.2613C23.1234 16.6713 21.0038 19.6482 22.2018 22.4213L23.519 24.6972L23.7662 25.0959L23.7392 25.0802L25.7868 28.6149L25.8318 28.7113C26.605 30.3509 28.2548 31.397 30.0708 31.397C31.8892 31.397 33.539 30.3509 34.3099 28.7113L34.3549 28.6149L34.4066 28.532Z"
          fill="#CDD2E8"
        />
      </svg>
    </div>
  </div>
);

export default Loader;
