import React from "react";

const FailedIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#FFD3D8" />
    <circle cx="20" cy="20" r="15" fill="#FF6A7C" />
    <g clipPath="url(#clip0)">
      <path
        d="M25.8361 14.0403L25.9662 14.1537C26.1825 14.3699 26.3131 14.6572 26.3339 14.9623C26.3548 15.2674 26.2644 15.5698 26.0796 15.8135L25.9662 15.9435L21.7899 20.1199L25.9662 24.2962C26.1825 24.5125 26.3131 24.7997 26.3339 25.1049C26.3548 25.41 26.2644 25.7124 26.0796 25.956L25.9662 26.0861C25.7499 26.3023 25.4627 26.433 25.1576 26.4538C24.8524 26.4746 24.5501 26.3843 24.3064 26.1994L24.1763 26.0861L20 21.9097L15.8237 26.0861C15.6074 26.3023 15.3201 26.433 15.015 26.4538C14.7099 26.4746 14.4075 26.3843 14.1639 26.1994L14.0338 26.0861C13.8175 25.8698 13.6869 25.5826 13.6661 25.2774C13.6452 24.9723 13.7356 24.6699 13.9204 24.4263L14.0338 24.2962L18.2101 20.1199L14.0338 15.9435C13.8175 15.7272 13.6869 15.44 13.6661 15.1349C13.6452 14.8297 13.7356 14.5274 13.9204 14.2837L14.0338 14.1537C14.2501 13.9374 14.5373 13.8068 14.8424 13.7859C15.1476 13.7651 15.4499 13.8555 15.6936 14.0403L15.8237 14.1537L20 18.33L24.1763 14.1537C24.3926 13.9374 24.6799 13.8068 24.985 13.7859C25.2901 13.7651 25.5925 13.8555 25.8361 14.0403L25.9662 14.1537L25.8361 14.0403Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="22.5" height="22.5" fill="white" transform="translate(8.75 8.75)" />
      </clipPath>
    </defs>
  </svg>
);

export default FailedIcon;
