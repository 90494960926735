import React, { createContext, useContext } from "react";

import ErrorModal, { ErrorModalProps } from "components/modals/errorModal";

const ErrorModalContext = createContext<{
  openErrorModal: (args: Partial<ErrorModalProps>) => void;
  close: VoidFunction;
}>({} as any);
const ErrorModalProvider: React.FC = ({ children }) => {
  const [modalProps, setModalProps] = React.useState<Partial<ErrorModalProps>>({
    open: false,
    error: undefined,
  });

  const openErrorModal = React.useCallback<(args?: Partial<ErrorModalProps>) => void>(
    (args?: Partial<ErrorModalProps>) => {
      setModalProps({
        ...args,
        open: true,
      });
    },
    []
  );

  const close = React.useCallback<VoidFunction>(() => {
    setModalProps((props) => ({ ...props, open: false }));
  }, []);

  const onToggleModal = React.useCallback(() => {
    setModalProps((props) => ({ ...props, open: !props.open }));
  }, []);

  const contextValue = React.useMemo(
    () => ({ openErrorModal, close }),
    [openErrorModal, close]
  );

  return (
    <>
      {modalProps.open && (
        <ErrorModal
          {...modalProps}
          open={modalProps.open}
          onClose={close}
          onToggleModal={onToggleModal}
        />
      )}
      <ErrorModalContext.Provider value={contextValue}>
        {children}
      </ErrorModalContext.Provider>
    </>
  );
};

const useErrorModalContext = () => useContext(ErrorModalContext);

const errorModalContextProvider = {
  Provider: ErrorModalProvider,
  useContext: useErrorModalContext,
};

export default errorModalContextProvider;
