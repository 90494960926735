import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import AppProvider from "./providers/appProvider";
import loadable from "./components/loadable/loadable";

const DappProvider = loadable(() => import("./providers/dappProvider"));

// General pages
const HomePage = loadable(() => import("./pages/home"));
const HowItWorksPage = loadable(() => import("./pages/howItWorks"));
const FAQ = loadable(() => import("./pages/faq"));
const CodeOfConductPage = loadable(() => import("./pages/codeOfConduct"));
const PrivacyPolicyPage = loadable(() => import("./pages/privacyPolicy"));

// App pages
const ReferPromoters = loadable(() => import("./pages/referPromoters"));
const SetupReferral = loadable(() => import("./pages/setupReferral"));
const MyReferralsDeFiReferrals = loadable(() => import("./pages/myReferrals/deFi"));
const MyReferralsNFTreferrals = loadable(() => import("./pages/myReferrals/nft"));
const MyBountiesDeFi = loadable(() => import("./pages/myBounties/deFi"));
const MyBountiesNFT = loadable(() => import("./pages/myBounties/NFT"));
const DeFiReferrals = loadable(() => import("./pages/deFiReferrals"));
const NFTreferrals = loadable(() => import("./pages/NFTreferrals"));

// IDO
const SetupReferralIDO = loadable(() => import("./pages/IDO/setupReferral"));
const IDOReferralV1PromoterView = loadable(() => import("./pages/IDO/v1"));
const IDOReferralV2PromoterView = loadable(() => import("./pages/IDO/v2/promoterView"));
const IDOReferralV2SellerView = loadable(() => import("./pages/IDO/v2/sellerView"));

// Liquidity pool
const SetupReferralLiquidityProvision = loadable(
  () => import("./pages/liquidityPool/setupReferral")
);
const LiquidityPoolReferralProjectView = loadable(
  () => import("./pages/liquidityPool/projectViewWrapper")
);
const LiquidityPoolReferralPromoterView = loadable(
  () => import("./pages/liquidityPool/promoterViewWrapper")
);

// NFT drop
const SetupReferralNFTdrop = loadable(() => import("./pages/NFTdrop/setupReferral"));
const NFTdropPromoterView = loadable(() => import("./pages/NFTdrop/promoterView"));
const NFTdropProjectView = loadable(() => import("./pages/NFTdrop/projectView"));
const NFTdropEditProgram = loadable(() => import("./pages/NFTdrop/editProgram"));

// Staking
const Staking = loadable(() => import("./pages/staking"));

// Farming
const Farming = loadable(() => import("./pages/farming"));

function App() {
  return (
    <React.Suspense fallback={null}>
      <AppProvider>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/how-it-works">
              <HowItWorksPage />
            </Route>
            <Route exact path="/faq">
              <FAQ />
            </Route>

            <Route exact path="/code-of-conduct">
              <CodeOfConductPage />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route>
              {() => {
                return (
                  <DappProvider>
                    <Switch>
                      <Redirect exact from="/app" to="/app/nft-referrals" />
                      <Route exact path="/app/nft-referrals">
                        <NFTreferrals />
                      </Route>
                      <Route exact path="/app/defi-referrals">
                        <DeFiReferrals />
                      </Route>
                      <Route exact path="/setup-referral">
                        <SetupReferral />
                      </Route>
                      <Route path="/refer-promoters">
                        <ReferPromoters />
                      </Route>
                      <Route exact path="/my-referrals/defi-referrals">
                        <MyReferralsDeFiReferrals />
                      </Route>
                      <Route exact path="/my-referrals/nft-referrals">
                        <MyReferralsNFTreferrals />
                      </Route>
                      <Route exact path="/my-referrals">
                        <MyReferralsNFTreferrals />
                      </Route>
                      <Route exact path="/my-bounties/defi">
                        <MyBountiesDeFi />
                      </Route>
                      <Route exact path="/my-bounties/nft">
                        <MyBountiesNFT />
                      </Route>
                      <Route exact path="/my-bounties">
                        <MyBountiesNFT />
                      </Route>
                      <Redirect exact from="/bounties" to="/my-bounties" />
                      {/* NFT drop */}
                      <Route exact path="/setup-referral/nft-drop/network/:chainId">
                        <SetupReferralNFTdrop />
                      </Route>
                      <Route exact path="/app/nft-referrals/nft-drop/edit/:refHash">
                        <NFTdropEditProgram />
                      </Route>
                      <Route exact path="/app/nft-referrals/promoter/nft-drop/:refHash">
                        <NFTdropPromoterView />
                      </Route>
                      <Route exact path="/app/nft-referrals/project/nft-drop/:refHash">
                        <NFTdropProjectView />
                      </Route>

                      {/* IDO */}
                      <Route exact path="/setup-referral/ido/network/:chainId">
                        <SetupReferralIDO />
                      </Route>
                      <Redirect
                        from="/app/promoter/ido/v2/:id/:chainId"
                        to="/app/defi-referrals/promoter/ido/v2/:id/:chainId"
                      />
                      <Redirect
                        from="/app/seller/ido/v2/:id/:chainId"
                        to="/app/defi-referrals/seller/ido/v2/:id/:chainId"
                      />
                      <Route exact path="/app/defi-referrals/promoter/ido/v1/:id">
                        <IDOReferralV1PromoterView />
                      </Route>
                      <Route
                        exact
                        path="/app/defi-referrals/promoter/ido/v2/:id/:chainId"
                      >
                        <IDOReferralV2PromoterView />
                      </Route>
                      <Route exact path="/app/defi-referrals/seller/ido/v2/:id/:chainId">
                        <IDOReferralV2SellerView />
                      </Route>
                      {/* Liquidity pool */}
                      <Redirect
                        from="/app/defi-referrals/promoter/liquidity-pool/:refHash/:chainId"
                        to="/app/defi-referrals/promoter/liquidity-pool/:refHash"
                      />
                      <Redirect
                        from="/app/defi-referrals/seller/liquidity-pool/:refHash/:chainId"
                        to="/app/defi-referrals/project/liquidity-pool/:refHash"
                      />
                      <Route
                        exact
                        path="/app/defi-referrals/promoter/liquidity-pool/:refHash"
                      >
                        <LiquidityPoolReferralPromoterView />
                      </Route>
                      <Route
                        exact
                        path="/app/defi-referrals/project/liquidity-pool/:refHash"
                      >
                        <LiquidityPoolReferralProjectView />
                      </Route>
                      <Route exact path="/setup-referral/liquidity-pool/network/:chainId">
                        <SetupReferralLiquidityProvision />
                      </Route>

                      {/* Staking */}
                      <Route exact path="/staking">
                        <Staking />
                      </Route>

                      <Route exact path="/farming">
                        <Farming />
                      </Route>
                    </Switch>
                  </DappProvider>
                );
              }}
            </Route>
          </Switch>
        </Router>
      </AppProvider>
    </React.Suspense>
  );
}

export default App;
